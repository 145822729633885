<template>
  <div>
    <!-- title -->
    <h2>お知らせ</h2>

    <!-- detail -->
    <div class="news-detail">
      <!-- title news -->
      <div class="title-news">
        <time>{{ newsDetail.start_datetime | formatDate('YYYY年MM月DD日') }}</time>
        <p v-html="handleSanitizeHtml(newsDetail.title)" />
      </div>

      <!-- content news -->
      <div class="content-news">
        <div v-html="handleSanitizeHtml(newsDetail.description)"></div>
      </div>

      <!-- btn back to news list -->
      <router-link class="btn-back"
         :to="{ name: 'Profile.News.List', query:{ page: this.$route.query.page } }">お知らせ一覧へ</router-link>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import { handleSanitizeHtml } from '@/utils/helpers'
import Common from '@/mixins/common.mixin'

export default {
  name: 'NewsDetail',

  beforeRouteEnter (to, from, next) {
    const id = to.params.id
    store.dispatch('news/getNewsDetail', id).then(_ => next())
  },

  computed: {
    ...mapState('news', ['newsDetail'])
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  methods: {
    handleSanitizeHtml
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}

.news-detail {
  padding-top: 27px;
  @media #{$info-phone} {
    padding-top: 0;
  }
  .title-news {
    font-family: $font-family-title;
    margin-bottom: 18px;
    time {
      margin-bottom: 16px;
      @include font-size(16px);
      @media #{$info-phone} {
        font-size: 16px;
      }
    }
    p {
      font-family: $font-family-title-R;
      word-break: break-all;
      @include font-size(18px);
      @media #{$info-phone} {
        font-size: 16px;
      }
    }
  }
  .content-news {
    padding: 20px 0;
    border-top: 2px solid #CACBC6;
    border-bottom: 2px solid #CACBC6;
    margin-bottom: 45px;
    min-height: 300px;
    @media #{$info-phone} {
      margin-bottom: 30px;
    }
  }
  .btn-back {
    @include font-size(18px);
    font-family: $font-family-title;
    margin: auto;
    max-width: 357px;
    height: 51px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #C66C44;
    border-radius: 6px;
    @media #{$info-phone} {
      max-width: 100%;
      font-size: 18px;
    }
  }
}
</style>
